//  SWITCHER ELEMENTS
function switcher(uM) {
	$(uM).each(function() {
		var sw_event = 'click';
		if ($(this).is('select')) {
			sw_event = 'change';
		} else if ($(this).is('input[type="file"]')) {
			sw_event = 'change';
		} else {
			sw_event = 'click';
		}
		$(this).unbind(sw_event).bind(sw_event, function () {
			var $this = $(this),
				role = $this.attr('data-role');
			switch (role) {
				case 'ajax':
					$this.unbind('click');
					var action = eval($this.attr('data-function'));
					if (typeof action == 'function') {
						action($this);
						switcher($this);
					}
					return false;
					break
				case 'scroll':
					var target = $($this.attr('data-scroll-target')).offset().top;
					if ($this.attr('data-scroll-fix'))
						target = target - parseInt($this.attr('data-scroll-fix'));
					if (target < 0) {
						target = 0;
					}
					$('html, body').stop().animate({
						scrollTop: target
					}, 800);
					return false;
					break
				case 'activate':
					var target = $this.data('target'),
						relation = $this.data('relation');
					if (relation && relation === 'parent') {
						target = $this.closest(target);
					}
					if (relation && relation === 'self') {
						target = $this;
					}
					if ($this.attr('data-class')) {
						$(target).toggleClass($this.attr('data-class'));
					} else {
						$(target).toggleClass('active');
					}
					return false;
					break
				case 'addClass':
					var target = $this.data('target'),
						relation = $this.data('relation');
					if (relation && relation === 'parent') {
						target = $(this).closest(target);
					}
					if ($this.attr('data-class')) {
						$(target).addClass($this.attr('data-class'));
					} else {
						$(target).addClass('active');
					}
					return false;
					break
				case 'removeClass':
					var target = $this.data('target'),
						relation = $this.data('relation');
					if (relation && relation === 'parent') {
						target = $(this).closest(target);
					}
					if ($this.attr('data-class')) {
						$(target).removeClass($this.attr('data-class'));
					} else {
						$(target).removeClass('active');
					}
					return false;
					break
				default:
					console.log('Default is nothing');
			}
		});
	});
}

// CUTING TEXT FUNCTION
function textCut(uM) {
	$(uM).each(function () {
		$(this).removeClass('turned').find('.js-shave').replaceWith($(this).find('.js-shave').text());
		$(this).find('.js-shave-char').remove()
		$(this).not('.turned').shave($(this).outerHeight() + 5, { character: '...' }).addClass('turned');
	});
}

//  ELEMENT SCROLL FUNCTION
function fixator(uM, stoper, fix, mobile) {
	var topScroll = $(document).scrollTop(),
        topStoper = $(stoper).offset().top,
        response = mobile;
    if (fix) {
        topStoper = topStoper + fix;
    }
    if (response === true) {
        $(uM).removeClass('fixed', 'absolute', 'normal', $(window).width() <= 700);
	} else {
		$(uM).removeAttr('style')
			.toggleClass('normal', topScroll < topStoper)
			.toggleClass('fixed', topScroll >= topStoper && topScroll < topStoper + $(stoper).height())
			.toggleClass('absolute', topScroll > topStoper + $(stoper).height());
	}
}
// MASK FORM
function maskForm(uM) {
	$(uM).mask($(uM).data('mask'));
}

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}

//  MAIN FUNCTIONS
$(function () {
    var set = {
			switch: '.switch',
			menu: '.menuWrapper',
			dot: '.dot',
			uptop: '.uptop',
			navigation: '.navigation',
			phoneInpit: 'input[data-mask]',
			moveElement: '.move'
		},
		lastScrollTop = 0;
	function moveElement(elem) {  
		var $this = $(elem),
			desktop = $($this.attr('data-desktop')),
			mobile = $($this.attr('data-mobile'));
		if (desktop.length && mobile.length) {
			if ($(window).width() <= 700)
				$this.insertBefore(mobile);
			else
				$this.prependTo(desktop);
			switcher($this.find('.switch'));
		}
	}
	$(document).ready(function () {
		if ($(set.moveElement).length) {
			moveElement(set.moveElement);
		}
        if ($(set.switch).length) {
			switcher(set.switch);
		}
		if ($(set.dot).length) {
			textCut(set.dot);
		}
		if ($(set.phoneInpit).length) {
			maskForm(set.phoneInpit);
		}
		if ($(set.menu).length) {
			if ($(window).width() > 700) {
				$(set.menu).flexMenu({
					showOnHover: true
				}).addClass('flexed');
			} else {
				$(set.menu).removeAttr('style').removeClass('flexed').flexMenu({ undo: true, showOnHover: true });
			}
		}
		if ($(set.uptop).length) {
			fixator(set.uptop, '#content', 0, false);
        }
        if ($(window).width() >= 700) {
        } else {}
	});
	$(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if($(window).width() >= 700) {
            if ($(set.navigation).length) {
                $(set.navigation).toggleClass('hidden', st > lastScrollTop);
            }
        } else {
            if ($(set.navigation).length) {
                $(set.navigation).removeAttr('style').removeClass('hidden');
            }
        }
		// if (st > lastScrollTop) {}
		// else if (st == lastScrollTop) {}
		// else {}
		fixator(set.uptop, '#content');
		lastScrollTop = st;
	}).resize(function() {
		moveElement(set.moveElement);
        if ($(window).width() >= 700) {
        } else {
            if ($(set.navigation).length) {
                $(set.navigation).removeAttr('style').removeClass('hidden');
            }
        }

	});
});